/* eslint-disable no-undef */
const loadableReady = require('@loadable/component').loadableReady;
const ReactDOM = require('react-dom');

exports.onRouteUpdate = ({ location }) => {
  const pathsToRedirect = [
    '/pad',
    '/abgenbank',
    '/therapeutics',
    '/tools',
    '/abdiver',
    '/nanobodies',
  ];
  const path = location.pathname.replace(/\/$/, '');
  if (pathsToRedirect.includes(path)) {
    window.location.replace(`http://research.naturalantibody.com${path}`);
  }
};

exports.replaceHydrateFunction = () => {
  return (element, container, callback) => {
    loadableReady(() => {
      ReactDOM.render(element, container, callback);
    });
  };
};
